<template>
  <el-footer>
    <span>&copy;2020-2020 Power By Wzz</span>
    <br>
    <i class="el-icon-thumb"></i>
    <a href="https://gitee.com/wzhouzhou/vue_wzz_cloudMusic" target="_blank">高仿网易云音乐</a>
    /
    <a href="https://gitee.com/wzhouzhou/privateBlog" target="_blank">自研博客系统</a>
    /
    <span style="color: blueviolet">Q群: 970804317</span>
  </el-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
